import React, { useState, useEffect } from 'react';
import { HUBSPOT_LINK } from '../common/config.js';
import logo from '../common/assets/complex-text.svg';

const Header = () => {
  const [isOpen, setIsOpen] = useState(0);

  return (
    <header>
      <div className="container is-max-desktop">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item">
              <img style={{ maxHeight: '40px' }} src={logo} width="160" height="40" />
            </a>

            <a
              role="button"
              className={`navbar-burger ${isOpen ? 'is-active' : ''}`}
              onClick={() => setIsOpen(!isOpen)}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
            <div className="navbar-start">
              <a className="navbar-item">
                Updates
              </a>

              <a className="navbar-item">
                About Us
              </a>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <a href={HUBSPOT_LINK} target="_blank" className="button is-primary">
                    <strong>Stay in touch</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;