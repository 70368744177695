import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Section from './Section';
import icons from '../common/icons';
import { HUBSPOT_LINK } from '../common/config.js';
import groupChatFigure from '../common/assets/undraw_Group_chat.svg';
import meetingFigure from '../common/assets/undraw_Chat_re_re1u.svg';
import screenshot from '../common/assets/screenshot.png';

export const App = () => {
  return (
    <div>
      <Header />
      <main>
        <Section className="has-background-primary">
          <Section.Main
            title="Ditch the office"
            description="See why teams work best remote"
            imgSrc={screenshot}
          />
        </Section>
        <Section>
          <Section.Quote
            center
            title="We wanted to work from home, but hated typing everything..."
            description="So we came up with Complex, a virtual space where teams can talk, hang out, and stay in the loop."
          />
        </Section>
        <Section>
          <Section.List
            imgSrc={groupChatFigure}
          >
            <Section.List.Item
              title="Talk, share, or meet"
              description="Like rooms in an office, spaces are made up of topic themed channels where teams can communicate. Let each team set up their space however they want."
            />
            <Section.List.Item
              title="Voice chat instantly"
              description="When typing doesn't cut it, grab a seat in a voice channel to have a better conversation. They're also a great place to stay available while working."
            />
            <Section.List.Item
              title="Follow your team"
              description="See which channels people are working in and join them with a single click. No links or codes required."
            />
            <Section.List.Item
              title="Collaborate in new ways"
              description="Wish your space had a wiki page or ticket list? New channel types are a way for us to introduce all sorts of collaborative functionality."
            />
          </Section.List>
        </Section>
        <Section className="has-background-light">
          <Section.Cards
            title="An HQ for enterprise"
          >
            <Section.Cards.Card
              title="SSO"
              icon={icons.key}
            />
            <Section.Cards.Card
              title="Roles & Permissions"
              icon={icons.userTag}
            />
            <Section.Cards.Card
              title="Security & Compliance"
              icon={icons.shield}
            />
            <Section.Cards.Card
              title="Auditing"
              icon={icons.clipboardCheck}
            />
          </Section.Cards>
        </Section>
        <Section>
          <Section.Info
            title="Switching is easy"
            description="We'll help you import your existing Slack channels or you can get started fresh with preconfigured spaces. Why not give it a try?"
            imgSrc={meetingFigure}
          />
        </Section>
        <Section className="has-background-primary">
          <Section.CTA
            title="We're working to make Complex available soon"
            light
          >
            <a href={HUBSPOT_LINK} target="_blank" className="button is-primary is-inverted">Stay in touch</a>
          </Section.CTA>
        </Section>
      </main>
      <Footer />
    </div>
  );
}

export default App;