import React from 'react';
import logo from '../common/assets/complex-logo.svg';

const Footer = () => (
  <footer className="footer">
    <div className="container is-max-desktop">
      <div className="columns">
        <div className="column is-one-third">
          <img width="64" height="64" src={logo} />
        </div>
        <div className="column is-two-thirds">
          <nav className="columns is-multiline is-justify-content-flex-end is-variable is-8">
            <div className="column is-narrow">
              <div>Product</div>
              <div>
                <a>Features</a>
              </div>
              <div>
                <a>Updates</a>
              </div>
            </div>
            <div className="column is-narrow">
              <div>Resources</div>
              <div>
                <a>Blog</a>
              </div>
              <div>
                <a>Privacy</a>
              </div>
            </div>
            <div className="column is-narrow">
              <div>Company</div>
              <div>
                <a>About Us</a>
              </div>
              <div>
                <a>Press Kit</a>
              </div>
              <div>
                <a>Follow Us</a>
              </div>
              <div>
                <a>Jobs</a>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <p className="has-text-centered">

      </p>
    </div>
  </footer>
);

export default Footer;