import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Section = ({ children, className }) => (
  <section className={`section ${className}`}>
    <div className="container is-max-desktop">
      {children}
    </div>
  </section>
);

const isSize = (size) => size === 'large' ? 'large' : '';
const isLight = (light) => light ? 'has-text-white' : '';
const isCenter = (center) => center ? 'has-text-center' : '';

Section.Info = ({ title, description, extra, imgSrc }) => (
  <div className="columns is-vcentered is-variable is-8-desktop">
    <div className="column">
      <p className="title">
        {title}
      </p>
      <p className="subtitle">
        {description}
      </p>
    </div>
    <div className="column">
      <figure className="image">
        <img src={imgSrc} width="400" />
      </figure>
    </div>
  </div>
);

Section.Quote = ({ title, description }) => (
  <div className="columns is-vcentered is-multiline is-justify-content-space-around">
    <div className="column is-12 desktop">
      <p className="title has-text-centered is-italic">
        "{title}"
      </p>
    </div>
    <div className="column is-10">
      <p className="subtitle has-text-centered is-size-4 has-text-info-dark">
        {description}
      </p>
    </div>
  </div>
);

Section.Main = ({ title, description, imgSrc }) => (
  <div className="columns is-vcentered is-variable is-8-desktop">
    <div className="column">
      <p className="title is-size-2 has-text-white">
        {title}
      </p>
      <p className="title is-size-4 has-text-white">
        {description}
      </p>
    </div>
    <div className="column">
      <figure className="image">
        <img src={imgSrc} width="400" style={{ boxShadow: 'rgba(0,0,0,0.4) 3px 3px 8px', borderRadius: '4px' }} />
      </figure>
    </div>
  </div>
);

Section.List = ({ title, imgSrc, children }) => (
  <div className="columns is-variable is-8-desktop">
    <div className="column">
      <figure className="image">
        <img src={imgSrc} width="400" />
      </figure>
    </div>
    <div className="column">
      <div>
        {children}
      </div>
    </div>
  </div>
);

Section.List.Item = ({ title = '', description = '', icon }) => (
  <div className="block">
    <p className="title is-size-5">
      {title}
    </p>
    <p className="subtitle is-size-6">
      {description}
    </p>
  </div>
);

Section.Cards = ({ title, children }) => (
  <div>
    <div className="block">
      <p className="title has-text-centered">
        {title}
      </p>
    </div>
    <div className="block">
      <div className="columns is-justify-content-space-around is-multiline">
        {children}
      </div>
    </div>
  </div>
);

Section.Cards.Card = ({ title, icon }) => (
  <div className="column is-half-tablet is-one-quarter-desktop">
    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left is-align-self-center">
            <figure className="image is-48x48" style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon icon={icon} size="2x" />
            </figure>
          </div>
          <div className="media-content is-align-self-center">
            <p className="title is-6">{title}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Section.CTA = ({ title, children }) => (
  <div>
    <p className="title has-text-white has-text-centered">
      {title}
    </p>
    <div className="has-text-centered">
      {children}
    </div>
  </div>
);

export default Section;