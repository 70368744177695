import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

// TODO: Set up redux store if we outgrow hooks.

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
